import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-custom-one-column',
  styleUrls: ['./custom-one-column.component.scss'],
  template: `
  <nb-layout >
    <!-- <nb-layout-header fixed>
      <ngx-custom-header></ngx-custom-header>
    </nb-layout-header> -->

    <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive >

    <img src="../../../../assets/drawable-xxxhdpi/drawable-xxxhdpi/NuSaaS B&Y.png" width="55%" style="margin-bottom:70px;margin-top:-10px;margin-left:35px" (click)="locate()">
      <ng-content select="nb-menu"></ng-content >
    </nb-sidebar>

    <nb-layout-column>
      <ng-content select="router-outlet"></ng-content>
    </nb-layout-column>
<!--
    <nb-layout-footer fixed>
      <ngx-footer></ngx-footer>
    </nb-layout-footer> -->
  </nb-layout>
`,
})
export class CustomOneColumnComponent {
  constructor(private router:Router){

  }
  locate(){
    this.router.navigate(['/landingpage/homepage'])
  }
}
