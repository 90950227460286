import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService, NbToastrService, NB_WINDOW } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { ApiserviceService } from '../../../apiservice.service';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  img = false;
  options: any = {};
  themeSubscription: any;
  baseurl = 'http://64.4.160.98:11000';
  options1: any = {};
  themeSubscription1: any;
  options2: any = {};
  themeSubscription2: any;
  public rating: number = 5; // you may already have a rating property.
  public rating1: number = 0; // you may already have a rating property.
  public rating2: number = 0; // you may already have a rating property.
  public rating3: number = 0; // you may already have a rating property.
  public rating4: number = 0; // you may already have a rating property.
  public rating5: number = 0; // you may already have a rating property.
  public rating6: number = 0; // you may already have a rating property.

  //new code
  myTimeline = [{}, {}, {}, {},]
  chart;
  dialogHeadTxt = '';
  imgUrl = '';
  displayMaximizable: boolean = false;



  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu = [{ title: "Home", link: '/landingpage/homepage' }, { title: 'Log out' }];

  constructor(private nbMenuService: NbMenuService, @Inject(NB_WINDOW) private window, private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserData,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService, private router: Router, private apiservice: ApiserviceService, private toastrService: NbToastrService) {
  }

  ngOnInit() {
    this.profile();

    this.nbMenuService.onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'my-context-menu'),
        map(({ item: { title } }) => title),
      )
      .subscribe(title => {
        if (title == "Profile") {
          this.display = true;
          this.profile();
        }

        if (title == "Change Password") {
          this.display1 = true;
          this.password1();
        }
        if (title == "Log out") {
          this.signout();
        }
      });





    this.currentTheme = this.themeService.currentTheme;

    this.userService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => this.user = users.nick);

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.router.navigate(['/landingpage/homepage'])
  }




  signout() {
    localStorage.removeItem('auth_token');
    localStorage.removeItem('auth_data');
    localStorage.removeItem('username');
    localStorage.removeItem('password');
    localStorage.removeItem('profileimage');
    this.router.navigate(["/landingpage/homepage"]);
    console.log("session ended");
  }
  display = false;
  userdata;
  email;
  firstname;
  lastname;
  phone;
  username;
  companyid;
  image;
  profile() {
    this.companyid = JSON.parse(localStorage.getItem('auth_data'));
    this.apiservice.getcompanyprofile(this.companyid.user_id).subscribe((data) => {
      console.log("user data", data);

      this.userdata = data;

      console.log("authdata", this.userdata)
      this.email = this.userdata.user.email,
        this.username = this.userdata.user.username;
      this.firstname = this.userdata.user.first_name;
      this.lastname = this.userdata.user.last_name;
      this.phone = this.userdata.phone;
      this.image = this.userdata.image;
      this.filename = this.userdata.image;
      localStorage.setItem('profileimage', this.image);


    });


  }
  submitupdate() {
    const body = {

      "phone": this.phone,
      "email": this.email,

      "first_name": this.firstname,
      "last_name": this.lastname,

    }
    this.apiservice.updatecompanyprofile(this.userdata.user.id, body).subscribe((Data) => {
      console.log("updated profile", Data)
      this.toastrService.show('success', `Profile Data Update`);
      this.uploadImage();
      this.profile();
    }, (Error) => {
      console.log("fail;ed update profile", Error)
      this.toastrService.show('success', Error)
    })

  }

  password;
  newpassword;
  oldpassword;
  display1 = false;
  password1() {
    this.password = JSON.parse(localStorage.getItem('password'));
    this.display1 = true;
  }

  useridd;
  passwordchange() {
    this.useridd = JSON.parse(localStorage.getItem('auth_data'));
    const body = {
      "old_password": this.oldpassword,
      "new_password": this.newpassword
    }
    this.apiservice.changepassword(this.useridd.user_id, body).subscribe((data) => {
      console.log("password changed", data)
      localStorage.setItem('password', JSON.stringify(this.newpassword));
      this.password = JSON.parse(localStorage.getItem('password'));

      this.toastrService.show('success', `Password  Changed`)
      this.oldpassword = null;

    }, (error) => {
      console.log("failed to change password", error)
      this.toastrService.show('Failed', `Failed To Change Password`)
    })
  }



  filename;
  selectedFile: File;
  fileError = '';

  onFileSelected(event, files) {
    if (event.target.files.length > 0) {
      console.log(event.target.files[0].name);
      this.filename = event.target.files[0].name;
    }
    this.selectedFile = files[0];
    console.log("here profile pic uploaded", this.selectedFile);
  }
  uploadImage() {
    // this.categoryIDForImage = this.addedCatID == null ? this.category_id : this.addedCatID;
    this.apiservice.uploadcompanyImage(this.userdata.user.id, this.selectedFile).subscribe(
      (imgData) => {
        console.log('image data ', imgData);
        console.log('image updated successfully');
        //  this.selectedFile=null
        //  window.location.reload();
        window.location.reload();
      },
      (error) => console.error(error)
    )
  }
}
